import Vue from 'vue';
import Vuex, { Store } from 'vuex';
import merge from 'lodash/merge';

import getters from './getters';

import GroupUriModule, { runGroupUriWatch } from './modules/groupUriModule';
import MetadataModule, { Country } from './modules/metadataModule';
import CutoutModule from './modules/cutoutsModule';
import WindowModule from './modules/windowModule';
import OverlayModule, { runOverlayWatch } from './modules/overlayModule';
import ModulesLoadModule, { START_LOADING } from './modules/modulesLoadModule';
import ToastModule from './modules/toastModule';
import EpiserverModule, { runEpiServerWatch } from './modules/epiServerModule';

import { RootState } from './types';

Vue.use(Vuex);

let store: Store<RootState> = null;

function fixInitialState(initialState?: RootState, isSSR?: boolean) {
  if (initialState) {
    const meta = initialState.Meta as any;

    if (meta.Meta) {
      Object.assign(meta, meta.Meta);
      delete meta.Meta;
    }
    if (meta.Cutouts) {
      initialState.Cutouts = meta.Cutouts;
      delete meta.Cutouts;
    }

    initialState.Modules.PageData.Slots.forEach(({ Modules }) => {
      const emptyModule = Modules.find((m) => !m.Type);
      if (emptyModule) {
        Modules.splice(Modules.indexOf(emptyModule), 1);
      }
    });

    initialState.Modules.PageData.Slots.forEach((s) => {
      let lastGroup = '';
      for (let i = 0; i < s.Modules.length; i++) {
        const element = s.Modules[i];
        if (lastGroup && !element.GroupUri) {
          element.GroupUri = lastGroup;
        } else {
          if (element.GroupUri) {
            lastGroup = element.GroupUri;
          }
        }

        if (element.Type === 'AnchorBarModule' && initialState?.PageUri) {
          initialState.PageUri.HasAnchorBar = true;
        }
      }
    });

    if (initialState?.PageUri?.BaseUrl && !initialState?.PageUri?.BaseUrl.endsWith('/')) {
      initialState.PageUri.BaseUrl += '/';
    }
  }
}

export const createStore = async (initialState?: RootState, isSSR?: boolean) => {
  fixInitialState(initialState, isSSR);

  store = new Store<RootState>({
    strict: true,
    // state: initialState,
    getters,
    modules: {
      Window: WindowModule,
      PageUri: GroupUriModule(initialState?.PageUri),
      Meta: MetadataModule(initialState?.Meta),
      Cutouts: CutoutModule(initialState?.Cutouts),
      Overlay: OverlayModule,
      Modules: ModulesLoadModule,
      Toast: ToastModule,
      EpiServer: EpiserverModule,
    },
  });

  if (initialState) {
    store.replaceState(merge({}, initialState, store.state));
  }

  await store.dispatch(START_LOADING);

  if (!isSSR) {
    if (!runEpiServerWatch(store)) {
      runOverlayWatch(store);
      runGroupUriWatch(store);
    }
  }

  return store;
};

export function getStore() {
  return store;
}

export function shouldShowOptIn() {
  const countryId = getStore().state.Meta.Country
  return countryId !== Country.US && countryId !== Country.AUS
}
